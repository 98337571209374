.registerContainer {
  max-width: 768px;
  margin: 0 auto;
  padding: 15px 0;
}

.registerTop {
  display: grid;
  grid-template-columns: 1fr 166px 1fr;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 34px;
  &__link {
    width: 14px;
  }
  &__left {
    width: 14px;
    height: 24px;
  }
  &__img {
    width: 166px;
    height: 46px;
  }
}

.form {
  &__trial {
    margin-top: 15px;
    color: #078179;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
}

.register {
  &__links {
    &__link {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #078179;
      margin-top: 15px;
    }
  }
  &__img {
    width: 100%;
    height: 300px;
    @media (max-width: 425px) {
      height: 200px;
    }
    object-position: top;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}
.wrapper {
  min-height: 100vh;
  position: relative;
}

//.background {
//  background: url("public/assets/backgrounds/blue_background.svg");
//}
