.input {
  width: 100%;
  color: #000000;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 18px;
  padding: 16px 20px;
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    /* border: 1px solid green; */
    -webkit-text-fill-color: #000000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  &::placeholder {
    color: #000000;
  }
  &[type="number"] {
    text-align: right;
  }
  &Login {
    padding: 16px 0;
    border-bottom: 1px solid #949494;
    &::placeholder {
      color: #a1a1a1;
    }
  }
  &Settings {
    color: #616161;
  }
}
.inputWrapper {
  position: relative;
}
.showPassword {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    fill: #999;
  }
}
