.addIngredient {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #949494;
    font-size: 14px;
    padding: 10px 4px;
    &::placeholder {
      color: #a1a1a1;
    }
  }
  &Button {
    margin: 32px auto 0;
    z-index: 2;
    cursor: pointer;
    border-radius: 22px;
    width: max-content;
    display: block;
    padding: 12px 32px;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    background-color: #078179;
    border: 1px solid #078179;
  }
  &Hide {
    display: none;
  }
  &Wrapper {
    background-color: #fff;
    width: 100%;
    position: absolute;
    z-index: 101;
    height: 100%;
    padding-bottom: 50px;
  }
  &OpenBtn {
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f8b857;
    // background-image: url("../../../public/assets/icon/plus.svg");
    // background-size: contain;
    cursor: pointer;
    z-index: 10;
    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
  }
}
