.healit {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  ul {
    padding: 8px 0 8px 16px;
  }
  ol {
    padding: 8px 0 8px 32px;
  }
  p {
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    height: auto;
  }
  &Meta {
    max-width: 768px;
    margin: 0 auto;
    padding: 0 20px;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    p {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 5px;
      color: #0a5e46;
    }
    h1 {
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
}
