.login {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  justify-items: center;
  &Logo {
    width: 160px;
    @media (max-height: 700px) {
      width: 120px;
    }
  }
  &Subtitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  &Image {
    max-height: 400px;
    @media (max-height: 700px) {
      max-height: 300px;
    }
  }
  &Form {
    width: 100%;
    margin-bottom: 40px;
  }
  &Forgot {
    display: inline-block;
    margin-top: 8px;
    color: #f8b857;
    font-weight: 800;
  }
  &Link {
    a {
      color: #f8b857;
      font-weight: 800;
    }
  }
  &Blob {
    width: 100%;
    height: 300px;
    @media (max-width: 425px) {
      height: 200px;
    }
    object-position: top;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}
.wrapper {
  min-height: 100vh;
  position: relative;
}
