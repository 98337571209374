.filters {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  &Hide {
    display: none;
  }
  &Wrapper {
    background-color: #fff;
    width: 100%;
    position: absolute;
    z-index: 101;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
  &Filter {
    &Header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    &Items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      &Item {
        margin-right: 8px;
        margin-bottom: 8px;
        &Label {
          cursor: pointer;
          border-radius: 22px;
          display: block;
          padding: 12px 32px;
          border: 1px solid #707070;
          white-space: normal;
          word-wrap: break-word;
        }
        &Input {
          display: none;
        }
        &Input:checked + &Label {
          background-color: #f8b857;
          border: 1px solid #f8b857;
        }
      }
    }
  }
  &Button {
    margin-left: auto;
    z-index: 2;
    cursor: pointer;
    border-radius: 22px;
    width: max-content;
    display: block;
    padding: 12px 32px;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    background-color: #078179;
    border: 1px solid #078179;
  }
  &OpenBtn {
    position: fixed;
    bottom: 60px;
    right: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f8b857;
    background-image: url("../../../public/assets/icon/filter_icon.svg");
    background-size: contain;
    cursor: pointer;
    z-index: 10;
  }
}
