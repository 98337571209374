.accordion {
  &Icon {
    width: 32px;
    height: 32px;
  }
  &Header {
    margin-bottom: 0 !important;
    padding: 0;
    font-size: 18px;
  }
  &Title {
    display: grid;

    &Img {
      grid-template-columns: 22px 1fr 22px;
    }
    gap: 20px;
    align-items: center;
    height: 58px;
    width: 100%;
    color: #000000;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 26px;
      width: 20px;
      height: 20px;
      background: url("../../../public/assets/icon/down.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      z-index: 10;
    }
    &Open {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &Content {
    text-align: justify;
  }
}
