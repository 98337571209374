.primaryButton {
  display: flex;
  justify-content: center;
  width: 100%;
  &__btn {
    width: 320px;
    height: 61px;
    padding: 18px;
    margin: 33px 0 0;
    font-size: 18px;
    background: #f8b857;
    color: #ffffff;
    font-weight: bold;
    border-radius: 30px;
  }
}
