.Search {
  margin-bottom: 30px;
  position: relative;
  &Icon {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }
  &Input {
    border: none;
    padding: 10px 5px;
    border-bottom: 1px solid #949494;
    width: 100%;
    outline: none;
    &::placeholder {
      color: #a1a1a1;
    }
  }
  ul {
    background-color: #fff;
    width: 100%;
    list-style-type: none;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 5;
    max-height: calc(4 * 40px);
    overflow-y: scroll;
    a {
      text-decoration: none;
      color: #000;
    }
    li {
      height: 40px;
      padding: 10px 5px;
      border-bottom: 1px solid #949494;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
