.healit {
  max-width: 768px;
  margin: 0 auto;
  &Search {
    height: 70px;
    background-color: #f2f9ff;
    display: grid;
    grid-template-columns: 1fr 16px;
    padding: 20px;
    gap: 10px;
    align-items: center;
    input {
      border: none;
      position: relative;
      outline: none;
      background-color: transparent;
    }
    img {
      display: block;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  &Discover {
    margin-bottom: 32px;
    &Header {
      padding: 20px;
    }
    &List {
      padding: 20px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 468px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &Item {
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        position: relative;
        padding-top: 63.64%;
        p {
          position: absolute;
          padding: 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        text-shadow: 1px 1px 0px #000000e6;
        font-size: 18px;
        color: #fff;
        font-weight: 800;
      }
    }
    &Btn {
      width: 70%;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
