.addIngredientOpenBtn {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f8b857;
  // background-image: url("../../../public/assets/icon/plus.svg");
  // background-size: contain;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 24px;
    height: 24px;
    fill: #fff;
  }
}
.ingredients {
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
  &TopBtns {
    height: 64px;
    display: flex;
    justify-content: space-between;
    &Btn {
      width: 64px;
      height: 64px;
      svg {
      }
      &Active {
        svg {
          fill: #f8b857;
        }
      }
    }
  }
  &List {
    margin-bottom: 68px;
    &Item {
      padding: 20px;
      &Header {
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        padding: 0 0 10px;
        &Side {
          text-align: right;
        }
      }
      &Ingredients {
        &Ingredient {
          padding: 10px 0;
          &:last-child {
            padding-bottom: 0;
          }
          display: grid;
          gap: 10px;
          grid-template-columns: 18px 1fr 18px;
          button {
            width: 18px;
            height: 18px;
            border: none;
            cursor: pointer;
            position: relative;
            border-radius: 50%;
            div {
              width: 100%;
              height: 100%;
              svg {
                width: 100%;
                height: 100%;
                fill: #999;
              }
            }
          }
          input {
          }
          label {
            font-size: 18px;
          }
          &Side {
            grid-auto-flow: dense;
            grid-template-columns: 18px 1fr 18px;
            text-align: right;
            input {
              order: 3;
            }
            label {
              order: 2;
            }
          }
          &Checked {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
.weekly {
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
  &TopBar {
    display: grid;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    grid-template-columns: 1fr repeat(2, 48px);
    &Date {
      padding: 0 20px;
      height: 32px;
      line-height: 32px;
      border-top: 2px solid #eee;
      position: relative;
      &Label {
        cursor: pointer;
        color: #f8b857;
        font-weight: bold;
      }
      &Calendar {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        &Overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: #0004;
        }
      }
    }
  }
}
