.blob {
  width: 100%;
  height: 200px;
  @media (min-width: 425px) {
    height: 300px;
  }
  @media (min-width: 1750px) {
    height: 400px;
  }
  @media (min-width: 1950px) {
    height: 500px;
  }
  object-position: top;
  object-fit: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.blob__yellow {
  width: 100%;
  height: 300px;
  @media (min-width: 620px) {
    height: 265px;
  }
  object-position: top;
  object-fit: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.blob__green {
  width: 100%;
  height: 175px;
  @media (min-width: 445px) {
    height: 265px;
  }
  @media (min-width: 665px) {
    height: 300px;
  }
  @media (min-width: 800px) {
    height: 400px;
  }
  @media (min-width: 1120px) {
    height: 500px;
  }
  @media (min-width: 1120px) {
    height: 500px;
  }
  @media (min-width: 1650px) {
    height: 600px;
  }
  @media (min-width: 1900px) {
    height: 700px;
  }
  object-position: top;
  object-fit: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.blob__red {
  width: 100%;
  height: 150px;
  @media (min-width: 600px) {
    height: 265px;
  }
  @media (min-width: 1150px) {
    height: 350px;
  }
  @media (min-width: 1750px) {
    height: 450px;
  }
  object-position: top;
  object-fit: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
