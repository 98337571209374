.activation {
  max-width: 768px;
  margin: 0 auto;
  padding: 15px 0;
  display: grid;
  justify-items: center;
  &Logo {
    width: 160px;
    @media (max-height: 700px) {
      width: 120px;
    }
  }
  &Subtitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  &Image {
    max-height: 400px;
    @media (max-height: 700px) {
      max-height: 300px;
    }
  }
  &Form {
    width: 100%;
    margin-bottom: 40px;
  }
  &Header {
    text-align: center;
  }
  &Text {
    margin-top: 32px;
  }
}
