.ModalPencil {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 80px - 50px);
  background-color: #0009;
  backdrop-filter: blur(4px);
  left: 0;
  top: 80px;
  z-index: 100;
  display: grid;
  place-items: center;
  &Exit {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    cursor: pointer;
    svg {
      transform: rotate(45deg);
      width: 48px;
      height: 48px;
      fill: #fff;
    }
  }
  &Tiles {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 0;
    &Tile {
      cursor: pointer;
      width: 160px;
      border-radius: 14px;
      border: 1px solid #fff;
      padding: 40px 20px;
      margin: 10px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 20px;
      svg {
        width: 64px;
        height: 64px;
        fill: #fff;
      }
      p {
        margin: 0;
        margin-top: 10px;
        font-weight: 800;
        white-space: normal;
      }
    }
  }
  &DelModal {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
    padding: 20px;
    text-align: center;
    &Header {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    &Description {
      font-size: 20px;
      font-weight: normal;
      white-space: normal;
      margin-bottom: 32px;
    }
    &Btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &Btn {
        cursor: pointer;
        border-radius: 14px;
        border: 1px solid #fff;
        padding: 14px 22px;
        margin: 10px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 20px;
        &:nth-last-child(1) {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}
