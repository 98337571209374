.Search {
  margin-bottom: 10px;
  position: relative;
  &Icon {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }
  &Input {
    border: none;
    padding: 10px 5px;
    border-bottom: 1px solid #949494;
    width: 100%;
    outline: none;
    &::placeholder {
      color: #a1a1a1;
    }
  }
  ul {
    background-color: #fff;
    width: 100%;
    list-style-type: none;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 5;
    max-height: calc(4 * 40px);
    overflow-y: scroll;
    a {
      text-decoration: none;
      color: #000;
    }
    li {
      height: 40px;
      padding: 10px 5px;
      border-bottom: 1px solid #949494;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.IngrList {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  &Item {
    background-color: #f3f4f6;
    display: grid;
    grid-template-columns: 1fr 24px;
    margin: 0 8px 8px 0;
    padding: 8px 8px 8px 12px;
    gap: 16px;
    align-items: center;
    border-radius: 10px;
    color: #1f2937;
    &Label {
      font-size: 16px;
      line-height: 16px;
    }
    &Btn {
      width: 24px;
      height: 24px;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 2px;
        background-color: #1f2937;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
