.doshaTest {
  max-width: 768px;
  margin: 0 auto;
  padding: 40px 20px 0;
  position: relative;
  min-height: calc(100vh - 130px);
  padding-bottom: 64px;
  &Start {
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      text-align: justify;
      margin-bottom: 10px;
    }
  }
  &Desc {
    text-align: center;
  }
  &Description {
    h2 {
      text-align: center;
      margin: 0;
    }
    h3 {
      text-align: center;
      font-size: 16px;
      margin: 0 0 16px 0;
      color: #333;
    }
    &Items {
      padding: 0 20px;
    }
    &Item {
      margin-bottom: 16px;
      align-items: center;
      display: grid;
      gap: 16px;
      text-align: center;
      svg {
        max-width: 96px;
        max-height: 96px;
      }
      b {
        display: block;
        font-size: 32px;
        margin-bottom: 4px;
      }
    }
  }
}
.progressBox {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
}
.steps {
  font-weight: 800;
  text-align: right;
}

.progress {
  width: 100%;
  height: 20px;
  border: 2px solid #000;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  background-color: #333;
  &Bar {
    transition: 1s;
    width: 0;
    background-color: #f8b857;
    height: 100%;
    // border-radius: 10px;
    transition: 0.5s;
  }
}
.accrodionContent {
  padding: 16px 20px;
  p {
    margin-bottom: 8px;
  }
}
.answers {
  display: grid;
  gap: 10px;
  grid-auto-rows: 1fr;
  margin-bottom: 10px;
  &Answer {
    &:has(input:checked) {
      background-color: #f8b857;
      border: 2px solid#f8b857;
      color: #fff;
    }
    cursor: pointer;
    border: 2px solid #222;
    border-radius: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    padding: 10px;
  }
}
.btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 20px;

  &Btn {
    background-color: #fff;
    border: 2px solid #222;
    padding: 10px;
    height: 38px;
    border-radius: 19px;
    font-weight: 800;
    &Active {
      background-color: #f8b857;
      border: 2px solid #f8b857;
      color: #fff;
    }
  }
}
.dashboard {
  &Latest {
    margin-top: 20px;
    &Header {
      padding: 20px;
    }
    &List {
      padding: 0;
      width: 100%;
      white-space: nowrap;
      display: grid;
      grid-gap: 20px;
      grid-auto-flow: column;
      overflow-x: auto;
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
      grid-auto-columns: minmax(calc(25% - 20px), 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
        grid-auto-columns: minmax(calc(50% - 20px), 1fr);
      }
      &Item {
        text-decoration: none;
        color: #000;
        display: block;
        width: 100%;
        position: relative;
        &Love {
          position: absolute;
          top: 24px;
          right: 0;
          width: 24px;
          height: 24px;
          cursor: pointer;
          background-color: #fff;
          display: grid;
          place-items: center;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &Img {
          width: 100%;
          position: relative;
          padding-top: 63.64%;
          background-color: #ddd;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          margin-bottom: 4px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
          }
        }
        p {
          white-space: normal;
        }
      }
    }
  }
}
