.onboarding {
  padding-top: 170px;
  width: 100%;
  min-height: 100vh;
  background: #fbf8ea
    url("../../../public/assets/images/splash_screen_background.webp") no-repeat;
  background-position: center 100%;
  @media (min-width: 768px) {
    background-position: center 50%;
  }
  background-size: 250%;

  &Subtitle {
    font-size: 40px;
    font-weight: 800;
    text-align: center;
  }
  &Title {
    font-family: "FaveScriptPro";
    font-size: 150px;
    text-align: center;
    color: #f8b857;
  }
  &Btn {
    margin: 0 auto 170px;
    height: 60px;
    padding: 20px 40px;
    border-radius: 30px;
    background-color: #078179;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #fff;
    svg {
      width: 30px;
      height: 30px;
      transform: rotate(180deg);
      fill: #fff;
    }
    &Skip {
      font-weight: normal;
      text-decoration: underline;
      border: 1px solid #000;
      background-color: transparent;
      color: #000;
      margin: 0 auto 0;
    }
  }
  &Dots {
    padding-top: 140px;
    margin: 0 auto;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(4, 14px);
    gap: 7px;
    &Dot {
      background-color: #fff;
      border: 1px solid #000;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      cursor: pointer;
      &Active {
        background-color: #f8b857;
      }
    }
  }
  &Slide {
    &Wrapper {
      margin: 0;
      background-color: #fbf8ea;
      min-height: 100vh;
    }
    max-width: 400px;
    margin: 0 auto;
    &Image {
      width: 100%;
      max-width: 400px;
      display: block;
      margin: 0 auto;
    }
    &Title {
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      position: relative;
      top: -20px;
      padding: 0 20px;
    }
  }
  &BtnNext {
    display: block;
    margin: 20px 20px 20px auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f8b857;
    svg {
      width: 30px;
      height: 30px;
      fill: #fff;
      transform: rotate(180deg);
    }
  }
}
