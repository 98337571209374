.Container {
  color: #000;
  height: 100%;
  position: relative;
  pointer-events: all;

  * {
    touch-action: initial !important;
  }
  & > div {
    width: 100% !important;
    height: 100% !important;
    pointer-events: all;
  }
  &Btn {
    position: absolute;
    z-index: 3;
    width: 64px;
    height: calc(100% - 100px);
    display: grid;
    &Hide {
      display: none;
    }
    svg {
      margin-top: 100px;
      width: 24px;
      height: 24px;
      fill: #fff;
    }
    &:nth-of-type(1) {
      place-items: center;
      right: 0;
    }
    &:nth-of-type(2) {
      place-items: center;
      left: 0;
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.Item {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  &Link {
    background-color: transparent;
    width: calc(100% - 128px);
    height: calc(100% - 100px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    pointer-events: all;
  }
  &Love {
    cursor: pointer;
    position: absolute;
    top: 48px;
    right: 0px;
    z-index: 5;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    background-color: #fff;
    & > div {
      width: 24px;
      height: 24px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &Meta {
    text-decoration: none;
    background-color: #00000042;
    width: 100%;
    height: 100px;
    color: #fff;
    padding: 20px;
    position: relative;
    pointer-events: all;
    &Date {
      font-size: 14px;
      margin-bottom: 4px;
    }
    &Title {
      font-size: 18px;
      font-weight: 800;
    }
  }
}
