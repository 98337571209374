$primary: #f8b857;
$grey100: #d2d2d2;
$grey200: #bebebe;
$black: #000000;
$white: #ffffff;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ion-menu {
  --width: 100vw;
}
ion-split-pane {
  --side-max-width: 100vw;
  --side-min-width: 100vw;
}
ion-header {
  &.headerOther {
    &::after {
      display: none;
    }
  }
}
.menu {
  width: 100vw;
  &Top {
    margin-bottom: 24px;
    height: 80px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    align-items: center;
    p {
      text-align: center;
      color: $primary;
      font-size: 32px;
      line-height: 32px;
      font-family: "FaveScriptPro";
    }
    &Icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
  &User {
    margin-bottom: 32px;
    display: grid;
    justify-items: center;
    &Image {
      position: relative;
      margin-bottom: 12px;
      &Img {
        width: 114px;
        height: 114px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      &Inpt {
        display: none;
      }
      &Lbl {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    &Name {
      font-size: 24px;
      line-height: 24px;
    }
  }
  &Items {
  }
  &Bg {
    width: 100%;
  }
}

.menuItem {
  cursor: pointer;
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  gap: 20px;
  height: 58px;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  padding: 0 20px;
  &Icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
      fill: $primary;
    }
  }
  &Title {
    color: $black;
    font-size: 18px;
  }
  &Arrow {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &Indent {
    padding-left: 72px;
  }
}

.menuBar {
  height: 80px;
  background: url("../../../public/assets/images/background_nav.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  align-items: center;
  box-shadow: none;
  .mainTitle {
    text-align: center;
    color: $white;
    font-size: 32px;
    line-height: 32px;
    font-family: "FaveScriptPro";
    white-space: nowrap; /* Zapobiega zawijaniu tekstu */
    overflow: hidden; /* Ukrywa tekst, który nie mieści się w obszarze */
    text-overflow: ellipsis;
    padding: 0 5px;
  }
  &Other {
    background: #fff;
    .mainTitle {
      color: $primary;
    }
  }
  ion-menu-button {
    height: 48px !important;
  }
  &Icon {
    width: 48px !important;
    height: 48px !important;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    display: grid;
    place-items: center;
    svg {
      width: 48px;
      height: 48px;
    }
    &Arrow {
      fill: $white;
      width: 32px;
      height: 32px;
      &Other {
        fill: #000;
      }
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
.social {
  margin: 10px 20px 0 auto;
  width: max-content;
  &Header {
    text-align: center;
    font-family: "FaveScriptPro";
    font-size: 32px;
    color: $grey200;
    margin-bottom: 4px;
  }
  &Links {
    display: grid;
    grid-template-columns: repeat(2, 24px);
    gap: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.bottomMenu {
  // bottom: 50px;
  position: relative;
  width: 100%;
  height: 20px;
  background-color: $white;
  height: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  &Icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
      fill: $grey100;
    }
    &Active {
      svg {
        fill: $primary;
      }
    }
  }
}

.skipBtn {
  text-decoration: none;
  position: relative;
  right: -6px;
  justify-self: flex-end;
  display: flex;
  align-items: center;
  font-weight: 800;
  text-transform: uppercase;
  color: #333;
  div {
    width: 32px !important;
    height: 32px !important;
  }
  svg {
    fill: #555;
    transform: rotate(180deg);
  }
}
