.payment {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px 20px 200px 20px;
  min-height: calc(100vh - 220px);
  &Days {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    span {
      color: #088179;
      font-weight: bold;
    }
  }
  &SubList {
    margin-bottom: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &Item {
      display: block;
      cursor: pointer;
      &:has(input:checked) {
        background-color: #f8b857;
        border: 2px solid#f8b857;
        color: #fff;
      }
      border: 2px solid #222;
      border-radius: 8px;
      display: grid;
      align-items: center;
      grid-template-columns: 20px 1fr;
      gap: 10px;
      padding: 10px;
      &Radio {
        cursor: pointer;
      }
      &Label {
        cursor: pointer;
      }
    }
  }
  &BuyBtn {
    margin-bottom: 10px;
    background-color: #088179;
    border: 2px solid#088179;
    &:disabled {
      background-color: #bbb;
      border: 2px solid #bbb;
      cursor: not-allowed;
    }
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;

    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    line-height: 18px;
    grid-column: 3/4;
    @media (max-width: 650px) {
      grid-column: 1/4;
    }
    &Wrap {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      &Code {
        display: grid;
        grid-template-columns: 1fr 48px;
        border: 2px solid #222;
        border-radius: 8px;
        overflow: hidden;
        &Wrap {
          grid-column: 3/4;
          @media (max-width: 650px) {
            grid-column: 1/4;
          }
          &Error {
            color: red;
            font-size: 12px;
            margin: 2px;
            font-weight: bold;
          }
        }
        input {
          width: 100%;
          border: none;
          line-height: 18px;
          padding: 6px;
        }
        button {
          border: none;
          background-color: #088179;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          border-left: 2px solid #222;
        }
      }
      &Summary {
        grid-column: 1/4;
        text-align: right;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  &History {
    &Header {
      font-size: 20px;
      margin: 20px 0 10px;
      text-align: center;
      font-weight: bold;
    }
    &Item {
      display: grid;
      grid-template-columns: 1fr 100px;
      align-items: center;
      padding: 16px 0;
      border-bottom: 2px solid #eee;
      &Description {
        p {
          &:first-child {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 2px;
          }
          &:last-child {
            font-size: 16px;
            color: #222;
          }
        }
      }
      &Cost {
        text-align: right;
      }
    }
  }
}
