.healit {
  max-width: 768px;
  margin: 0 auto;
  &Search {
    height: 70px;
    background-color: #f2f9ff;
    display: grid;
    grid-template-columns: 1fr 16px;
    padding: 20px;
    gap: 10px;
    align-items: center;
    input {
      border: none;
      position: relative;
      outline: none;
      background-color: transparent;
    }
    img {
      display: block;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  &Dosha {
    margin-left: 20px;
    width: 48px;
    height: 64px;
    margin-bottom: -64px;
    background: #81b3e0 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 44px 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    svg {
      width: 28px;
      height: 28px;
      fill: #fff;
      margin-bottom: 2px;
    }
    p {
      font-size: 8px;
      color: #fff;
      text-align: center;
      font-weight: 600;
    }
  }
  &Human {
    margin: 20px auto 0;
    max-width: 350px;
    position: relative;
    img {
      margin: 0 auto;
      display: block;
      max-width: 133px;
    }
    &Item {
      position: absolute;
      width: 120px;
      text-align: center;
      padding: 16px;
      color: #6d6d6d;
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #707070;
      text-decoration: none;
      &:nth-child(1) {
        top: 15%;
        left: 0;
      }
      &:nth-child(2) {
        top: 45%;
        left: 0;
      }
      &:nth-child(3) {
        top: 0;
        right: 0;
      }
      &:nth-child(4) {
        top: 20%;
        right: 0;
      }
      &:nth-child(5) {
        top: 40%;
        right: 0;
      }
      &:nth-child(6) {
        top: 70%;
        right: 0;
      }
      &:nth-child(7) {
        top: 30%;
        left: 0;
      }
    }
  }
  &Info {
    color: #a1a1a1;
    font-size: 12px;
    text-align: center;
    margin: 20px 0;
  }
  &Content {
    font-size: 14px;
    padding: 0 20px;
  }
  &DoshaIcon {
    width: 64px;
    height: 64px;
    margin: 64px auto;
  }
  &BlobBottom {
    position: relative;
    img {
      width: 100%;
      height: 300px;
      @media (max-width: 425px) {
        height: 200px;
      }
      object-position: top;
      object-fit: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  &BlobTop {
    position: relative;
    @media (min-width: 580px) {
      display: none;
    }
    img {
      width: 100%;
      height: 500px;
      opacity: 0.35;
      object-position: top;
      object-fit: cover;
      position: absolute;
      left: 0;
      bottom: -250px;
      z-index: -1;
    }
  }
}
