.Container {
  width: 100%;
  display: grid;
}
.Item {
  color: #000;
  text-decoration: none;
  width: 100%;
  display: block;
  position: relative;
  &Header {
    display: grid;
    grid-template-columns: 1fr 32px;
    gap: 10px;
    align-items: center;
    padding: 10px;
    a {
      color: #000;
      text-decoration: none;
    }
    svg {
      width: 32px;
      height: 32px;
      fill: #000;
    }
  }
  &Love {
    position: absolute;
    top: 48px;
    right: 0px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: #fff;
    display: grid;
    place-items: center;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  img {
    display: block;
    width: 100%;
    height: 144px;
    object-fit: cover;
    object-position: center;
    background-color: #ddd;
    margin-bottom: 4px;
  }
  p {
    white-space: normal;
    font-weight: 800;
  }
}
.Column {
  border-top: 1px solid #000;
  display: grid;
  width: 100%;
  min-height: 100%;
  height: max-content;
  padding: 0 20px;

  &Current {
    background: url("../../../public/assets/images/yellowBlob.svg") no-repeat
      center right;
  }

  &Header {
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr 48px;
    gap: 10px;
    align-items: center;
    font-size: 20px;

    svg {
      fill: #000;
    }
  }
  &Content {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
}
