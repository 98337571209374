.Container {
  color: #000;
  height: 300px;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  & > div {
    width: 100% !important;
    height: 100% !important;
  }
  &Btn {
    bottom: 50%;
    position: absolute;
    z-index: 3;
    width: 32px;
    height: 32px;
    display: grid;
    place-items: center;
    &Hide {
      display: none;
    }
    svg {
      width: 24px;
      height: 24px;
      fill: #fff;
    }
    &:nth-of-type(1) {
      right: 8px;
    }
    &:nth-of-type(2) {
      left: 8px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.Item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  &Love {
    position: absolute;
    top: 48px;
    right: 0px;
    z-index: 2;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    background-color: #fff;
    & > div {
      width: 24px;
      height: 24px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &Meta {
    background-color: #00000042;
    width: 100%;
    height: 100px;
    color: #fff;
    padding: 20px;

    &Date {
      font-size: 14px;
      margin-bottom: 4px;
    }
    &Title {
      font-size: 18px;
      font-weight: 800;
    }
  }
}
