.popover {
  --width: 100%;
  --max-width: 512px;
  &Btn {
    --background: transparent;
    --box-shadow: none;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  p {
    margin-bottom: 16px;
  }
  ul {
    padding-left: 16px;
    margin-bottom: 16px;
  }
  li {
    margin-bottom: 8px;
  }
}

.weekly {
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
  &TopBar {
    display: grid;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    grid-template-columns: 1fr repeat(3, 48px);
    &Date {
      position: relative;
      &Label {
        cursor: pointer;
        color: #f8b857;
        font-weight: bold;
      }
      &Calendar {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        &Overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: #0004;
        }
      }
    }
    &Header {
      text-decoration: underline;
      color: #000;
      cursor: pointer;
    }
    &Btn {
      svg {
        fill: #c5c5c5;
      }
      &Active {
        svg {
          fill: #f8b857;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  animation: fadeIn 1s ease-in-out;
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
