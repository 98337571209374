.recipes {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;

  &Search {
    margin-bottom: 30px;
    position: relative;
    &::after {
      content: "";
      display: block;
      background: url("../../../public/assets/icon/search.svg") no-repeat center;
      background-size: contain;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
    }
    &Input {
      border: none;
      padding: 10px 5px;
      border-bottom: 1px solid #949494;
      width: 100%;
      outline: none;
      &::placeholder {
        color: #a1a1a1;
      }
    }
    ul {
      background-color: #fff;
      width: 100%;
      list-style-type: none;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      z-index: 2;
      li {
        padding: 10px 5px;
        border-bottom: 1px solid #949494;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
      }
    }
  }
  &Tiles {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-bottom: 40px;
    &Tile {
      background-size: cover;
      width: 100%;
      aspect-ratio: 3/2;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 0 10px 0px #0000003d;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
          to bottom right,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
        );
      }
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        padding: 20px;
        font-size: 36px;
        font-weight: 900;
        color: #fff;
        text-shadow: 1px 1px 0px #000000de;
      }
    }
  }
}
