.login {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  justify-items: center;
  &Logo {
    width: 160px;
    @media (max-height: 700px) {
      width: 120px;
    }
  }
  &Subtitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  &Image {
    max-height: 400px;
    @media (max-height: 700px) {
      max-height: 300px;
    }
  }
  &Form {
    width: 100%;
    margin-bottom: 40px;
  }
  &Link {
    a {
      color: #f8b857;
      font-weight: 800;
    }
  }
  &Blob {
    width: 100%;
    height: 300px;
    @media (max-width: 425px) {
      height: 200px;
    }
    object-position: top;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}
.wrapper {
  min-height: 100vh;
  position: relative;
}
h1 {
  text-align: center;
}
.cms {
  &Back {
    position: fixed;
    bottom: 32px;
    left: 32px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f8b857;
    // background-image: url("../../../public/assets/icon/plus.svg");
    // background-size: contain;
    cursor: pointer;
    z-index: 10;
    display: grid;
    place-items: center;
    div {
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }
  }
}
