.ionList {
  padding: 0;
  position: relative;
  background: transparent;
  & .ionSelect {
    min-height: 55px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px 20px;
    // background: url("../../../public/assets/icon/right.svg") no-repeat right;
    background-size: 12px;
    outline: none;
    font-size: 18px;
    position: relative;
    &::after {
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 20px;
      height: 20px;
      background: url("../../../public/assets/icon/down.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      z-index: 10;
    }
    &::part(placeholder) {
      font-size: 18px;
      color: #000000;
      opacity: 1;
    }
    &::part(icon) {
      display: none !important;
    }
    &OnlyLabel {
      &::part(text) {
        display: none;
      }
    }
  }
  & .ionItem {
    &::part(native) {
      padding-left: 0;
    }
  }
  & .ionLabel {
    position: absolute;
    font-size: 18px;
    padding: 16px 20px;
    width: max-content;
    height: 0;
    z-index: 2;
    pointer-events: none;
  }
}

ion-select::part(icon) {
  display: none !important;
}
