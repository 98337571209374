.dashboard {
  max-width: 768px;
  margin: 0 auto;
  &TopBtns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &Btn {
      height: 140px;
      line-height: 140px;
      color: #ffffff;
      text-shadow: 1px 1px 4px #000000d6;
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      background-size: cover;
      background-position: center;
      &:first-child {
        background-image: url("../../../public/assets/images/cook_it_image.png");
      }
      &:last-child {
        background-image: url("../../../public/assets/images/heal_it_image.png");
        opacity: 0.5;
      }
    }
  }
  &PlanBtns {
    display: grid;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    grid-template-columns: 1fr repeat(2, 48px);
    &Header {
      color: #f8b857;
      text-decoration: none;
      font-weight: bold;
      cursor: pointer;
    }
    &Btn {
      svg {
        fill: #c5c5c5;
      }
      &Active {
        svg {
          fill: #f8b857;
        }
      }
    }
  }
  &Content {
    height: 460px;
  }
  &Latest {
    &Header {
      padding: 20px;
      color: #f8b857;
      text-decoration: none;
      font-weight: bold;
    }
    &List {
      padding: 0 20px 20px;
      width: 100%;
      white-space: nowrap;
      display: grid;
      grid-gap: 20px;
      grid-auto-flow: column;
      overflow-x: auto;
      grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
      grid-auto-columns: minmax(calc(25% - 20px), 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
        grid-auto-columns: minmax(calc(50% - 20px), 1fr);
      }
      &Item {
        text-decoration: none;
        color: #000;
        display: block;
        width: 100%;
        position: relative;
        &Love {
          position: absolute;
          top: 24px;
          right: 0;
          width: 24px;
          height: 24px;
          cursor: pointer;
          background-color: #fff;
          display: grid;
          place-items: center;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &Img {
          width: 100%;
          position: relative;
          padding-top: 63.64%;
          background-color: #ddd;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          margin-bottom: 4px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
          }
        }
        p {
          white-space: normal;
        }
      }
    }
  }
  &Cite {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 250px;

    &Content {
      text-align: center;
      font-family: "FaveScriptPro";
      font-size: 36px;
      margin-bottom: 8px;
    }
    &Author {
      text-align: right;
      font-size: 12px;
    }
  }
  &Quest {
    box-shadow: 0px 0px 9px #0000002e;
    padding: 32px 0;
    &Header {
      width: 70%;

      margin: 0 auto 16px;
    }
    &Btn {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      color: #000;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &Discover {
    margin-bottom: 32px;
    &Header {
      padding: 20px;
      color: #f8b857;
      text-decoration: none;
      font-weight: bold;
    }
    &List {
      padding: 0 20px 24px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &Item {
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        position: relative;
        padding-top: 63.64%;
        p {
          position: absolute;
          padding: 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        text-shadow: 1px 1px 0px #000000e6;
        font-size: 18px;
        color: #fff;
        font-weight: 800;
      }
    }
    &Btn {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      color: #000;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &Texts {
    margin-bottom: 64px;
    &Header {
      padding: 20px;
      font-size: 18px;
    }
    &Dosha {
      display: flex;
      justify-content: space-around;
      padding: 20px;
      &Item {
        width: 72px;
        display: grid;
        gap: 8px;
        place-items: center;
      }
    }
    &List {
      margin-bottom: 32px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &Item {
        padding: 20px;
        position: relative;
        &Img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          height: auto;
          object-fit: cover;
          z-index: -1;
        }
        &Header {
          font-weight: 800;
          font-size: 18px;
          margin-bottom: 4px;
        }
        &Content {
          font-size: 18px;
        }
      }
    }
    &Btn {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      color: #000;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &Fridge {
    margin: 64px 0 16px;
    padding: 0 20px;
    &Img {
      display: block;
      margin: 0 auto 16px;
      max-width: 512px;
      width: 100%;
    }
    &Header {
      text-align: center;
      font-size: 36px;
      line-height: 36px;
      font-family: "FaveScriptPro";
      margin-bottom: 8px;
    }
    &Description {
      text-align: center;
      margin-bottom: 16px;
    }
    &Btn {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      color: #000;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
