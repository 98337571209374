.weekly {
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
  &TopBar {
    height: 70px;
    display: grid;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    grid-template-columns: 1fr repeat(2, 48px);
    &Date {
      position: relative;
      &Label {
        cursor: pointer;
        color: #f8b857;
        font-weight: bold;
      }
      &Calendar {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        &Overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: #0004;
        }
      }
    }
    &Header {
      text-decoration: underline;
      color: #000;
      cursor: pointer;
    }
    &Btn {
      svg {
        fill: #c5c5c5;
      }
      &Active {
        svg {
          fill: #f8b857;
        }
      }
    }
  }
}
