.healit {
  max-width: 768px;
  margin: 0 auto;
  &Search {
    height: 70px;
    background-color: #f2f9ff;
    display: grid;
    grid-template-columns: 1fr 16px;
    padding: 20px;
    gap: 10px;
    align-items: center;
    input {
      border: none;
      position: relative;
      outline: none;
      background-color: transparent;
    }
    img {
      display: block;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  &List {
    &Item {
      display: grid;
      grid-template-columns: 1fr 24px;
      padding: 20px;
      border-bottom: 1px solid #d2d2d2;
      text-decoration: none;
      &Title {
        font-size: 18px;
        color: #000;
      }
      &Icon {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
