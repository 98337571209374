.splash{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f0e6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    img{
        width: 300px;
        height: 300px;
    }
}