.items {
  margin: 0 auto;
  display: grid;
  padding: 0 20px;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 400px;
  input {
    display: none;
  }
  &Item {
    display: grid;
    cursor: pointer;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #707070;
    p {
      padding-top: 16px;
      text-align: center;
    }
    input:nth-of-type(1):checked ~ &:nth-of-type(1) {
      background-color: #94a3cb;
      border: 1px solid transparent;
      p {
        color: #fff;
      }
    }
    input:nth-of-type(2):checked ~ &:nth-of-type(2) {
      background-color: #e24a49;
      border: 1px solid transparent;
      p {
        color: #fff;
      }
    }
    input:nth-of-type(3):checked ~ &:nth-of-type(3) {
      background-color: #088179;
      border: 1px solid transparent;
      p {
        color: #fff;
      }
    }
  }
}
