.recipes {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px 20px 200px 20px;
  &Love {
    position: absolute;
    bottom: 0px;
    right: 20px;
    z-index: 3;
    background-color: #fff;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    cursor: pointer;
    & > div {
      width: 32px;
      height: 32px;
    }
    svg {
      width: 32px;
      height: 32px;
      fill: #f8b857;
    }
  }
  &Slider {
    max-width: 768px;
    margin: 0 auto;
    position: relative;
    &Doshas {
      position: absolute;
      top: 0;
      left: 8px;
      z-index: 3;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      &Dosha {
        width: 48px;
        height: 64px;
        background: #81b3e0 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 44px 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 8px;
        svg {
          width: 28px;
          height: 28px;
          fill: #fff;
          margin-bottom: 2px;
        }
        p {
          font-size: 8px;
          color: #fff;
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
  &Header {
    display: grid;
    grid-template-columns: 1fr 48px;
    gap: 48px;
    margin-bottom: 32px;
    &Box {
      &Title {
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        margin: 0 0 4px 0;
      }
      &Time {
        text-align: left;
        font-size: 12px;
        color: #000000;
      }
    }
    &Btn {
      svg {
        width: 48px;
        height: 48px;
        fill: #f8b857;
      }
    }
  }
  &Servings {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 156px;
    &Header {
      color: #8a8a8a;
      font-size: 12px;
    }
    &Btns {
      display: grid;
      grid-template-columns: repeat(3, 52px);
      align-items: center;
      &Count {
        font-size: 24px;
        text-align: center;
      }
      &Btn {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        color: #fff;
        display: grid;
        place-items: center;
        svg {
          fill: #fff;
        }
        & > * {
          width: 28px;
          height: 28px;
        }
        &:nth-last-of-type(1) {
          background-color: #078179;
        }
        &:nth-last-of-type(2) {
          background-color: #f8b857;
        }
      }
    }
  }
  &Ingredients {
    &Header {
      color: #078179;
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 32px;
    }
    &List {
      list-style-type: none;
      &Item {
        margin-bottom: 16px;
        font-size: 14px;
      }
    }
  }
  &Info {
    p {
      font-size: 14px;
      color: #606060;
      padding: 24px 0;
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    hr {
      background-color: #707070;
      height: 1px;
      border: none;
    }
  }
  &Description {
    padding: 32px 0;
    ul {
      padding: 8px 0 8px 16px;
    }
    p {
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
      li {
        counter-increment: my-awesome-counter;
        margin: 0.25rem;
        position: relative;
        padding-left: 3rem;
        margin-bottom: 1rem;
      }
      li::before {
        position: absolute;
        left: 0;
        content: counter(my-awesome-counter);
        background: #078179;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: inline-block;
        line-height: 2rem;
        color: white;
        text-align: center;
        margin-right: 0.5rem;
      }
    }
  }
  &Notes {
    box-shadow: 2px 3px 6px #00000029;
    padding: 32px;
    border-radius: 28px;
    ul {
      padding: 8px 0 8px 16px;
    }
    ol {
      padding: 8px 0 8px 32px;
    }
    p {
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &AdditionalInformation {
    &Header {
      border-top: 1px solid #707070;
      color: #f8b857;
      font-size: 18px;
      font-weight: 800;
      padding: 32px 0 16px;
      margin-top: 32px;
    }
    ul {
      padding: 8px 0 8px 16px;
    }
    ol {
      padding: 8px 0 8px 32px;
    }
    p {
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
