.info {
  padding: 20px;
  background-color: #ffea8d;
  & > p {
    text-align: center;
    &:nth-child(1) {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 16px;
    }
    &:nth-child(2) {
      font-size: 14px;
      color: #000000;
    }
  }
}
.settings {
  max-width: 768px;
  margin: 0 auto;
  &Resetpassword {
    width: 100%;
    color: #000000;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    font-size: 18px;
    padding: 16px 20px;
    display: grid;
    align-items: center;
    text-decoration: none;
  }
  &DatePicker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    & > * {
      width: 100%;
      margin: 0 auto;
    }
  }
  &Cook {
    display: grid;
    grid-template-columns: 1fr 80px;
    &Label {
      width: 100%;
      color: #000000;
      border: none;
      border-bottom: 1px solid #d2d2d2;
      font-size: 18px;
      padding: 16px 20px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr max-content;
    }
    & * {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
  &TimePicker {
    width: 100%;
    color: #000000;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    font-size: 18px;
    padding: 16px 20px;
    outline: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      /* border: 1px solid green; */
      -webkit-text-fill-color: #000000;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &::placeholder {
      color: #000000;
    }
  }
}
.reminder {
  width: 100%;
  color: #000000;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 18px;
  padding: 16px 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content;
  font-weight: 600;
}

ion-toggle {
  // height: 10px;
  // width: 65px;

  padding: 4px 2px;

  --background: #ddd;
  --background-checked: #ddd;

  // --handle-background: #088179;
  --handle-background-checked: #088179;

  // --handle-width: 25px;
  // --handle-height: 27px;
  // --handle-max-height: auto;
  // --handle-spacing: 6px;

  // --handle-border-radius: 4px;
  // --handle-box-shadow: none;

  // /* Required for iOS handle to overflow the height of the track */
  // overflow: visible;
  // contain: none;
}
.item-inner {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  padding-inline-end: 0px !important;
}
