.healit {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  ul {
    padding: 8px 0 8px 16px;
  }
  ol {
    padding: 8px 0 8px 32px;
  }
  p {
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
