.cms {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  ul {
    padding: 8px 0 8px 16px;
  }
  ol {
    padding: 8px 0 8px 32px;
  }
  img {
    width: 100%;
    height: auto;
  }
  &Back {
    position: fixed;
    bottom: 32px;
    left: 32px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f8b857;
    // background-image: url("../../../public/assets/icon/plus.svg");
    // background-size: contain;
    cursor: pointer;
    z-index: 10;
    display: grid;
    place-items: center;
    div {
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }
  }
}
