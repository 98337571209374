.Container {
  min-height: calc(100vh - 80px - 50px - 70px);
  height: 100%;
  width: 100%;
  white-space: nowrap;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  grid-auto-columns: minmax(33%, 1fr);
  button {
    position: fixed;
    background-color: #068179;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    width: 300px;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
    &.disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
}
.Item {
  color: #000;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  display: block;
  position: relative;
  &Love {
    position: absolute;
    top: 24px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: #fff;
    display: grid;
    place-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  img {
    display: block;
    width: 100%;
    height: 120px;
    object-fit: cover;
    object-position: center;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 4px;
  }
  p {
    white-space: normal;
  }
}
.Column {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  height: max-content;
  border-right: 1px solid #ddd;
  padding-bottom: 50px;
  cursor: pointer;
  &Current {
    background-color: #fbf8ea;
  }
  &:last-child {
    border-right: none;
  }
  &Header {
    padding: 20px 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    text-transform: uppercase;
    display: grid;
    gap: 10px;
    font-weight: bold;
  }
  &Content {
    display: grid;
  }
  &Bottom {
    cursor: pointer;
    width: 100%;
    height: 50px;
    display: grid;
    place-items: center;
    bottom: 0;
    position: absolute;
    background-color: #f1f1f1;
    &Current {
      background-color: #e4dae9;
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }
}
