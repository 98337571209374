.doshaTest {
  max-width: 768px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  min-height: calc(100vh - 130px);
  padding-bottom: 64px;
  &Start {
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      text-align: justify;
      margin-bottom: 10px;
    }
  }
  &Description {
    h2 {
      text-align: center;
      margin: 0;
    }
    h3 {
      text-align: center;
      font-size: 16px;
      margin: 0 0 16px 0;
      color: #333;
    }
    &Items {
      padding: 32px 20px 0;
    }
    &Item {
      margin-bottom: 16px;
      align-items: center;
      display: grid;
      gap: 16px;
      grid-template-columns: 64px 1fr;
      b {
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}
.progressBox {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
}
.steps {
  font-weight: 800;
  text-align: right;
}

.progress {
  width: 100%;
  height: 20px;
  border: 2px solid #000;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  background-color: #333;
  &Bar {
    transition: 1s;
    width: 0;
    background-color: #f8b857;
    height: 100%;
    // border-radius: 10px;
    transition: 0.5s;
  }
}
.accrodionContent {
  padding: 16px 20px;
  p {
    margin-bottom: 8px;
  }
}
.answers {
  display: grid;
  gap: 10px;
  grid-auto-rows: 1fr;
  margin-bottom: 10px;
  &Answer {
    &:has(input:checked) {
      background-color: #f8b857;
      border: 2px solid#f8b857;
      color: #fff;
    }
    cursor: pointer;
    border: 2px solid #222;
    border-radius: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    padding: 10px;
  }
}
.btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 20px;

  &Btn {
    background-color: #fff;
    border: 2px solid #222;
    padding: 10px;
    height: 38px;
    border-radius: 19px;
    font-weight: 800;
    &Active {
      background-color: #f8b857;
      border: 2px solid #f8b857;
      color: #fff;
    }
  }
}
.dashboard {
  &Discover {
    margin-bottom: 32px;
    &Header {
      padding: 20px;
      color: #f8b857;
      text-decoration: none;
      font-weight: bold;
    }
    &List {
      padding: 0 0 24px;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &Item {
        text-decoration: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
        position: relative;
        padding-top: 63.64%;
        p {
          position: absolute;
          padding: 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        text-shadow: 1px 1px 0px #000000e6;
        font-size: 18px;
        color: #fff;
        font-weight: 800;
      }
    }
    &Btn {
      width: 100%;
      max-width: 300px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 16px;
      gap: 24px;
      box-shadow: 0px 3px 5px #00000029;
      background-color: #fbf8ea;
      margin: 0 auto;
      padding: 0 32px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      text-align: left;
      color: #000;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
