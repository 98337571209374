.Container {
  height: 100%;
  width: 100%;
  // overflow: auto;
  white-space: nowrap;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  grid-auto-columns: minmax(33%, 1fr);
}
.Item {
  color: #000;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  display: block;
  position: relative;
  &Love {
    position: absolute;
    top: 24px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: #fff;
    display: grid;
    place-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  img {
    display: block;
    width: 100%;
    height: 120px;
    object-fit: cover;
    object-position: center;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 4px;
  }
  p {
    white-space: normal;
  }
}
.Column {
  display: inline-block;
  width: 100%;
  min-height: 100%;
  height: max-content;
  border-right: 1px solid #ddd;
  &Current {
    background-color: #ebe1f0;
  }
  &:last-child {
    border-right: none;
  }
  &Header {
    padding: 20px 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    text-transform: uppercase;
    display: grid;
    gap: 10px;
  }
  &Content {
    display: grid;
  }
}
